$(document).ready(function()
{
    document.querySelector("#sub-form").addEventListener('submit', SubscribeEvent);
        
    function SubscribeEvent( event ) 
    {
        event.preventDefault();
        var _form = $(this);
        var _Result = true;
        
        $(_form).find('input[type="text"], input[type="checkbox"]').not('input[name="Telefono"]').each(function (idx, e)
        {
            if($(e).val() == '' || ($(e).attr("type") == "checkbox" && !$(e).is(':checked')))
            {
                $(e).addClass('is-invalid');

                $("#span-" + $(e).attr("name")).css("display", "block");

                if($(e).attr("type") == "checkbox")
                {
                    $("#span-" + $(e).attr("name") + " strong").text('Debe aceptar las Politicas de Privacidad y los Terminos y Condiciones para porse suscribir');
                }
                else
                {
                    $("#span-" + $(e).attr("name") + " strong").text('Requerido');
                }

                _Result = false;
            }
            else
            {
                $(e).removeClass('is-invalid');

                $("#span-" + $(e).attr("name")).css('display', 'none');
                $("#span-" + $(e).attr("name") + " strong").text('');
            }
        });

        if(_Result)
        {
            $("body").css("cursor", "progress");

            $.ajax({
                url: _form.attr("action"),
                method: "POST",
                data: $(_form).serialize(),
                success:function(data)
                {
                    $('#div-sub').html(data);

                    if ( $( "#sub-form" ).length ) 
                    {
                        document.querySelector("#sub-form").addEventListener('submit', SubscribeEvent);
                    }

                    $("body").css("cursor", "default");
                },
                error:function( jqXHR, textStatus, errorThrown )
                {
                    $("body").css("cursor", "default");
                    
                    return false;
                }
            })
        }
    }
}); 